<template>
  <div class="user">
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked">
        <span>
          {{ nome }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a href="#/perfil">
                  <span class="sidebar-mini">P</span>
                  <span class="sidebar-normal">Perfil</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  components: {
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      default: "Tania Andrew",
    },
    rtlTitle: {
      type: String,
      default: "تانيا أندرو",
    },
    avatar: {
      type: String,
      default: "./img/faces/avatar.jpg",
    },
  },
  data() {
    return {
      isClosed: true,
      nome: "",
    };
  },
  methods: {
    clicked: function (e) {
      e.preventDefault();
    },
    inicio() {
      var user = JSON.parse(localStorage.getItem("appBackofficeLogin"));
      var username = user.nome;
      this.nome = username;
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
  },
  mounted() {
    this.inicio();
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
