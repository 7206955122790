<template>
  <div>
    <div class="md-layout centercenter">
      <div class="md-layout-item md-size-50 mx-auto text-center">
        <h2 class="title">O melhor plano para si</h2>
        <h5 class="description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation
        </h5>
      </div>
    </div>
    <div class="md-layout centercenter">
      <div
        class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 md-size-25">
        <pricing-card icon-color="icon-success">
          <h6 slot="category" class="category">Básico</h6>
          <md-icon slot="icon">home</md-icon>
          <h3 slot="title" class="title">25€/mês</h3>
          <p slot="description" class="card-description">
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor</span>
            <ul>
              <li>Gestão de reservas <md-icon style="color: #8C82FC; top:0">done</md-icon></li>
              <li>Calendário personalizado <span class="erro">X</span></li>
              <li>Número de estabelecimentos - <b>1</b></li>
              <li>Número de reservas - <b>Ilimitado</b></li>
            </ul>

          </p>
          <md-button slot="footer" class="md-success md-round"  @click="planoBasico()"
            >Escolher Plano</md-button
          >
        </pricing-card>
      </div>
      <div
        class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 md-size-25">
        <pricing-card icon-color="icon-success">
          <h6 slot="category" class="category"><b>Premium</b></h6>
          <md-icon slot="icon">calendar_today</md-icon>
          <h3 slot="title" class="title">50€/mês</h3>
          <p slot="description" class="card-description">
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor</span>
            <ul>
              <li>Gestão de reservas <md-icon style="color: #8C82FC; top:0">done</md-icon></li>
              <li>Calendário personalizado <md-icon style="color: #8C82FC; top:0">done</md-icon></li>
              <li>Número de estabelecimentos - <b>2</b></li>
              <li>Número de reservas - <b>Ilimitado</b></li>
            </ul>
          </p>
          <md-button slot="footer" class="md-success md-round"
            >Escolher Plano</md-button
          >
        </pricing-card>
      </div>
      <div
        class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 md-size-25">
        <pricing-card icon-color="icon-success">
          <h6 slot="category" class="category">Premium Extra</h6>
          <md-icon slot="icon">domain_add</md-icon>
          <h3 slot="title" class="title">75€/mês</h3>
          <p slot="description" class="card-description">
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor</span>
            <ul>
              <li>Gestão de reservas <md-icon style="color: #8C82FC; top:0">done</md-icon></li>
              <li>Calendário personalizado <md-icon style="color: #8C82FC; top:0">done</md-icon></li>
              <li>Número de estabelecimentos - <b>5</b></li>
              <li>Número de reservas - <b>Ilimitado</b></li>
            </ul>
          </p>
          <md-button slot="footer" class="md-success md-round"
            >Escolher Plano</md-button
          >
        </pricing-card>
      </div>
    </div>
    
  </div>
</template>
<script>
import { PricingCard } from "@/components";
import { Modal } from "@/components";
import api from "../../api";
// import eupago from '@api/eupago';
export default {
  components: {
    PricingCard,
  },
  data(){
    return{
      pagamento:false
    }
  },
  methods: {
    async planoBasico(){
      let data = {
          "payment": {
            "amount": {"currency": "EUR", "value": 50},
            "lang": "PT",
            "successUrl": "https://teste-rc.republica45.pt/#/obrigado?user_id=xxxxxxxxx",
            "failUrl": "https://teste-rc.republica45.pt/#/falhou?user_id=xxxxxxxxx",
            "backUrl": "https://teste-rc.republica45.pt/#/planos",
            "identifier": "user_id"
          },
          "customer": {"notify": true, "email": "ricardogouveiacantador@gmail.com"}
        }
      let res = await api.pagamentoBasico(data)
      console.log(res)
      // eupago.auth('ApiKey demo-9d35-2ae2-1866-6d6');
      // eupago.creditCard({
      //   payment: {
      //     amount: {currency: 'EUR', value: 50},
      //     lang: 'PT',
      //     successUrl: 'https://teste-rc.republica45.pt/#/obrigado?user_id=xxxxxxxxx',
      //     failUrl: 'https://teste-rc.republica45.pt/#/falhou?user_id=xxxxxxxxx',
      //     backUrl: 'https://teste-rc.republica45.pt/#/planos',
      //     identifier: 'user_id'
      //   },
      //   customer: {notify: true, email: 'ricardogouveiacantador@gmail.com'}
      // })
      // .then(({ data }) => console.log(data))
      // .catch(err => console.error(err));
    }
}
};
</script>
<style scoped>
.text-center {
  text-align: center;
}
</style>
