<template>
  <div v-if="rendered">
    <div class="md-layout">
      <div class="md-layout-item md-size-80">
        <div class="md-layout-item md-small-size-100 md-size-33">
          <md-field>
            <label for="movies">Estabelecimento</label>
            <md-select
              v-model="estabelecimentoSelecionado"
              @input="alterarEstabelecimento"
              name="Estabelecimentos"
              id="Estabelecimentos">
              <md-option
                v-for="(item, i) in estabelecimentosDropDown"
                :key="i"
                :value="item.value"
                >{{ item.name }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <vue-cal
        :key="calendarKey"
        :events="events"
        :locale="local"
        activeView="day"
        :split-days="splitDays"
        :sticky-split-labels="stickySplitLabels"
        :min-cell-width="minCellWidth"
        :min-split-width="minSplitWidth"
        :editable-events="{
          title: false,
          drag: false,
          resize: false,
          delete: true,
          create: true,
        }"
        today-button
        @view-change="handleViewChange('view-change', $event)"
        :time-from="calendarioHoras.abre * 60"
        :time-to="calendarioHoras.fecha * 60"
        :time-step="30"
        :snap-to-time="15"
        :disable-views="['years', 'year', 'week']"
        @event-drag-create="dragCreate"
        :onEventDblclick="handleEventClick" />

      <modal v-if="verInformacoesModal" @close="verInformacoesModalHide">
        <template slot="header">
          <h4 class="modal-title">Informação da reserva</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="verInformacoesModal = !verInformacoesModal">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <div class="row" v-if="!eventoInformacao.utilizador">
            <div class="col text-left">
              <span class="erro"
                ><b
                  >Este cliente não tem conta na App, lorem ipsum ........</b
                ></span
              >
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-left">
              <span class="text-success bold">Nome do Cliente:</span>
              {{ eventoInformacao.nome_cliente }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-left">
              <span class="text-success bold">Telefone do Cliente:</span>
              {{ eventoInformacao.telefone_cliente }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-left">
              <span class="text-success bold">Email do Cliente:</span>
              {{ eventoInformacao.email_cliente }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-left">
              <span class="text-success bold">Horário:</span> Das
              {{ eventoInformacao.hora_inicio }} às
              {{ eventoInformacao.hora_fim }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-left">
              <span class="text-success bold">Funcionario:</span>
              {{ eventoInformacao.nome_funcionario }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-left">
              <span class="text-success bold">Detalhes:</span>
              {{ eventoInformacao.produto }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-left">
              <span class="text-success bold">Estado da Reserva:</span>
              {{ eventoInformacao.estado_reserva }}
            </div>
          </div>

          <div
            class="row mt-3"
            style="display: flex; gap: 50px"
            v-if="
              eventoInformacao.estado_reserva.toString().toLowerCase() !=
              'confirmada'
            ">
            <div
              class="md-size-33 text-left"
              v-if="eventoInformacao.passado == '0'">
              <md-button class="md-success" @click="aceitarReserva"
                >Aceitar reserva</md-button
              >
            </div>
            <div class="md-size-33 text-left">
              <md-button class="md-danger" @click="showSwal('apagar')"
                >Apagar reserva</md-button
              >
            </div>
          </div>
          <div
            class="row mt-3"
            style="display: flex; gap: 50px"
            v-if="
              eventoInformacao.estado_reserva.toString().toLowerCase() ==
              'confirmada'
            ">
            <div class="text-left" v-if="eventoInformacao.passado == '0'">
              <md-button class="md-danger" @click="showSwal('apagar')"
                >Apagar reserva</md-button
              >
            </div>

            <div class="text-left" v-if="eventoInformacao.passado == '1'">
              <md-button class="md-success" @click="aceitarReserva"
                >Compareceu
                <md-icon class="text-success">done</md-icon></md-button
              >
            </div>
            <div class="text-left" v-if="eventoInformacao.passado == '1'">
              <md-button class="md-warning" @click="aceitarReserva"
                >Não compareceu
                <md-icon class="text-success">close</md-icon></md-button
              >
            </div>
          </div>
        </template>
      </modal>
      <modal v-if="marcarReservaModal" @close="marcarReservaModalHide">
        <template slot="header">
          <h4 class="modal-title">Informação da reserva</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="marcarReservaModalHide">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <div class="row">
            <div class="col text-left">
              <md-field>
                <label>Nome do cliente</label>
                <md-input
                  v-model="formAdicionarReserva.nome_cliente"
                  type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="row">
            <div class="col text-left">
              <md-field>
                <label>Email do cliente</label>
                <md-input
                  v-model="formAdicionarReserva.email_cliente"
                  type="email"></md-input>
              </md-field>
            </div>
          </div>
          <div class="row">
            <div class="col text-left">
              <md-field>
                <label>Telefone do cliente</label>
                <md-input
                  v-model="formAdicionarReserva.telefone_cliente"
                  type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="row">
            <div class="col text-left">
              <md-field>
                <label for="movies">Produtos</label>
                <md-select
                  v-model="formAdicionarReserva.id_produto"
                  name="Produto"
                  id="Produtos">
                  <md-option
                    v-for="(item, i) in formAdicionarReserva.produto"
                    :key="i"
                    :value="item.value"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="row">
            <div class="col text-left">
              <md-field>
                <label for="movies">Funcionários</label>
                <md-select
                  v-model="formAdicionarReserva.id_funcionario"
                  name="Funcionario"
                  id="Funcionarios">
                  <md-option
                    v-for="(item, i) in formAdicionarReserva.funcionarios"
                    :key="i"
                    :value="item.value"
                    >{{ item.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="row" v-if="showErroAddReserva">
            <div class="col text-left">
              <p class="erro">Todos os campos tem de ser preenchidos.</p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col" style="display: flex; gap: 50px">
              <md-button class="md-success" @click="adicionarReserva"
                >Adicionar reserva</md-button
              >
              <md-button class="md-danger" @click="handleCancel"
                >Cancelar</md-button
              >
            </div>
          </div>
        </template>
      </modal>
    </div>
  </div>
  <div v-else>
    <div class="loading-overlay">
      <span class="fas fa-spinner fa-3x fa-spin"></span>
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/pt-br.cjs.js";
import axios from "axios";
import api from "../../api";
import { Modal } from "@/components";
import Swal from "sweetalert2";
export default {
  components: { VueCal, Modal },
  data() {
    return {
      calendarKey: 0,
      lastCreatedEvent: null,
      rendered: false,
      stickySplitLabels: true,
      drag: false,
      minCellWidth: 400,
      minSplitWidth: 0,
      local: "pt-br",
      splitDays: [],
      events: [],
      utilizadores: [],
      calendarioHoras: {
        abre: 0,
        fecha: 0,
      },
      showErroAddReserva: false,
      verInformacoesModal: false,
      marcarReservaModal: false,
      estabelecimentoSelecionado: "",
      estabelecimentosDropDown: [],
      allEstab: [],
      diaAtivo: null,
      formAdicionarReserva: {
        data: "",
        hora_inicio: "",
        hora_fim: "",
        funcionarios: [],
        id_funcionario: "",
        nome_funcionario: "",
        id_produto: "",
        nome_produto: "",
        produto: [],
        id_cliente: "NA",
        nome_cliente: "",
        email_cliente: "NA",
        telefone_cliente: "",
        estado_reserva: "confirmada",
        id_estabelecimento: "",
      },
    };
  },
  methods: {
    verInformacoesModalHide: function () {
      this.verInformacoesModal = false;
      this.calendarKey++;
    },
    marcarReservaModalHide: function () {
      this.marcarReservaModal = false;
      this.calendarKey++;
    },

    async inicio() {
      let user = JSON.parse(localStorage.getItem("appBackofficeLogin"));
      let estabelecimento_id = user.estabelecimento_id.split("|")[0];
      let json = {
        estabelecimento_id: localStorage.getItem("estabAtivo")
          ? localStorage.getItem("estabAtivo")
          : estabelecimento_id.split("|")[0],
      };
      this.events = [];
      this.splitDays = [];
      if (!localStorage.getItem("estabAtivo")) {
        localStorage.setItem("estabAtivo", estabelecimento_id.split("|")[0]);
      }

      this.estabelecimentoSelecionado = json.estabelecimento_id;
      var estabelecimentos = user.estabelecimento_id.split("|");
      var estabelecimentosDetalhes = [];
      let res = await api.buscarReservas(json);
      if (res) {
        if (res.resultado == "OK") {
          const temp = Object.values(res.data);
          const marcarCalendario = temp.map((reservation) => {
            const {
              data,
              hora_inicio,
              hora_fim,
              estado_reserva,
              nome_funcionario,
              id_funcionario,
              nome_cliente,
              telefone_cliente,
              email_cliente,
              fator_confianca,
              id_cliente,
              timestamp_reserva,
            } = reservation;
            const [year, month, day] = data.split("-").map(Number);
            const [startHour, startMinute, startSecond] = hora_inicio
              .split(":")
              .map(Number);
            const [endHour, endMinute, endSecond] = hora_fim
              .split(":")
              .map(Number);
            const start = new Date(
              year,
              month - 1,
              day,
              startHour,
              startMinute,
              startSecond
            );
            const end = new Date(
              year,
              month - 1,
              day,
              endHour,
              endMinute,
              endSecond
            );
            const differenceInMilliseconds = end - start;
            const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
            const event = {
              id: "124",
              data: "2024-08-10",
              hora_inicio: "12:00",
              hora_fim: "13:00",
            };

            const eventEndTime = new Date(`${data}T${hora_fim}`);

            const now = new Date();

            const isPast = eventEndTime < now;
            return {
              id: reservation.id.toString(),
              title: reservation.nome_produto,
              nome_funcionario: nome_funcionario,
              split: id_funcionario,
              id_funcionario: id_funcionario,
              data: data,
              nome_cliente: nome_cliente,
              telefone_cliente: telefone_cliente,
              email_cliente: email_cliente,
              fator_confianca: fator_confianca,
              timestamp_reserva: timestamp_reserva,
              estado_reserva: estado_reserva,
              id_cliente: id_cliente,
              start: this.formatDate(
                new Date(
                  year,
                  month - 1,
                  day,
                  startHour,
                  startMinute,
                  startSecond
                )
              ),
              end: this.formatDate(
                new Date(year, month - 1, day, endHour, endMinute, endSecond)
              ),
              // content:
              //   estado_reserva === "pendente"
              //     ? '<i class="icon material-icons icon_orange">update</i>'
              //     : '<i class="icon material-icons icon_green">check_circle</i>',
              allDay: false,
              passado: isPast ? 1 : 0,
              class:
                `${
                  estado_reserva === "pendente" ? "event-orange" : "event-green"
                } ` + `${differenceInMinutes > 30 ? "mais45" : ""}`,
            };
          });
          this.eventos = marcarCalendario;
          this.events = marcarCalendario;
          this.estabelecimentosDropDown = [];
          for (let i = 0; i < estabelecimentos.length; i++) {
            var jsonDetalhes = {
              id_interno: estabelecimentos[i],
            };
            let res2 = await api.buscarFuncionariosEstabelecimento(
              jsonDetalhes
            );
            estabelecimentosDetalhes.push(res2.resultado);
          }
          var func = JSON.parse(
            estabelecimentosDetalhes.find(
              (x) => x.id_interno == estabelecimento_id
            )?.funcionarios
          );
          for (let o = 0; o < func.length; o++) {
            this.splitDays.push({
              id: func[o].id,
              class: o % 2 === 0 ? "func1" : "func2",
              label: func[o].nome,
            });
          }

          localStorage.setItem(
            "estabelecimentos",
            JSON.stringify(estabelecimentosDetalhes)
          );

          for (let i = 0; i < estabelecimentosDetalhes.length; i++) {
            this.estabelecimentosDropDown.push({
              value: estabelecimentosDetalhes[i].id_interno,
              name: estabelecimentosDetalhes[i].nome,
            });
            estabelecimentosDetalhes[i].dias_aberto = JSON.parse(
              estabelecimentosDetalhes[i].dias_aberto
            );
            estabelecimentosDetalhes[i].funcionarios = JSON.parse(
              estabelecimentosDetalhes[i].funcionarios
            );
            estabelecimentosDetalhes[i].horario = JSON.parse(
              estabelecimentosDetalhes[i].horario
            );
            estabelecimentosDetalhes[i].produtos = JSON.parse(
              estabelecimentosDetalhes[i].produtos
            );
          }
          this.allEstab = estabelecimentosDetalhes;
          this.calendarioHorario();
          this.estabelecimentos = estabelecimentosDetalhes;
        } else if (
          res.resultado ==
          "Erro: Nenhuma reserva encontrada para este estabelecimento."
        ) {
          this.estabelecimentosDropDown = [];
          for (let i = 0; i < estabelecimentos.length; i++) {
            var jsonDetalhes = {
              id_interno: estabelecimentos[i],
            };
            let res2 = await api.buscarFuncionariosEstabelecimento(
              jsonDetalhes
            );
            estabelecimentosDetalhes.push(res2.resultado);
          }
          var func = JSON.parse(
            estabelecimentosDetalhes.find(
              (x) => x.id_interno == estabelecimento_id
            )?.funcionarios
          );
          for (let o = 0; o < func.length; o++) {
            this.splitDays.push({
              id: func[o].id,
              class: o % 2 === 0 ? "func1" : "func2",
              label: func[o].nome,
            });
          }
          localStorage.setItem(
            "estabelecimentos",
            JSON.stringify(estabelecimentosDetalhes)
          );
          for (let i = 0; i < estabelecimentosDetalhes.length; i++) {
            this.estabelecimentosDropDown.push({
              value: estabelecimentosDetalhes[i].id_interno,
              name: estabelecimentosDetalhes[i].nome,
            });
            estabelecimentosDetalhes[i].dias_aberto = JSON.parse(
              estabelecimentosDetalhes[i].dias_aberto
            );
            estabelecimentosDetalhes[i].funcionarios = JSON.parse(
              estabelecimentosDetalhes[i].funcionarios
            );
            estabelecimentosDetalhes[i].horario = JSON.parse(
              estabelecimentosDetalhes[i].horario
            );
            estabelecimentosDetalhes[i].produtos = JSON.parse(
              estabelecimentosDetalhes[i].produtos
            );
          }
          this.allEstab = estabelecimentosDetalhes;
          this.estabelecimentos = estabelecimentosDetalhes;
          this.eventos = [];
          this.calendarioHorario();
        }
        this.rendered = true;
      }
    },
    handleViewChange(view, objecto) {
      var data = objecto.startDate;
      this.diaAtivo = this.getCurrentDayInPortugueseHandleChange(data);
      this.calendarioHorario();

      // Your logic to handle the day change
    },
    calendarioHorario() {
      let user = JSON.parse(localStorage.getItem("appBackofficeLogin"));
      let estabelecimento_id = user.estabelecimento_id.split("|")[0];
      let json = {
        estabelecimento_id: localStorage.getItem("estabAtivo")
          ? localStorage.getItem("estabAtivo")
          : estabelecimento_id.split("|")[0],
      };
      if (this.allEstab.length > 0) {
        var estabHorario = this.allEstab.find(
          (x) => x.id_interno == json.estabelecimento_id
        )?.horario;
        const today = !this.diaAtivo
          ? this.getCurrentDayInPortuguese()
          : this.diaAtivo;
        const todaySchedule = estabHorario.find(
          (schedule) => schedule.dia === today
        );
        this.calendarioHoras.abre = Number(todaySchedule.abre.split(":")[0]);
        this.calendarioHoras.fecha = Number(todaySchedule.fecha.split(":")[0]);
      } else {
        this.calendarioHoras.abre = 7;
        this.calendarioHoras.fecha = 21;
      }
    },
    getCurrentDayInPortugueseHandleChange(handleChange) {
      const daysInPortuguese = [
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
        "Domingo",
      ];

      const today = new Date(handleChange).getDay();

      return daysInPortuguese[today === 0 ? 6 : today - 1];
    },
    getCurrentDayInPortuguese() {
      const daysInPortuguese = [
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
        "Domingo",
      ];

      const today = new Date().getDay();

      return daysInPortuguese[today === 0 ? 6 : today - 1];
    },
    checkAllFields(form) {
      return Object.keys(form).every((key) => this.isFilled(form[key]));
    },
    handleCancel() {
      this.calendarKey += 1;
      this.marcarReservaModal = false;
    },

    extrairData(data) {
      const date = new Date(data);
      const year = date.getFullYear();
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return `${year}-${month}-${day}`;
    },
    async adicionarReserva() {
      var estabelecimentos = JSON.parse(
        localStorage.getItem("estabelecimentos")
      );
      for (let i = 0; i < estabelecimentos.length; i++) {
        estabelecimentos[i].dias_aberto = JSON.parse(
          estabelecimentos[i].dias_aberto
        );
        estabelecimentos[i].funcionarios = JSON.parse(
          estabelecimentos[i].funcionarios
        );
        estabelecimentos[i].horario = JSON.parse(estabelecimentos[i].horario);
        estabelecimentos[i].produtos = JSON.parse(estabelecimentos[i].produtos);
      }
      var estabelecimentoAtivo = estabelecimentos.find(
        (x) => x.id_interno == localStorage.getItem("estabAtivo")
      );
      var id_produto = this.formAdicionarReserva.id_produto;
      var id_funcionario = this.formAdicionarReserva.id_funcionario;
      this.formAdicionarReserva.nome_produto =
        estabelecimentoAtivo.produtos.find((x) => x.id == id_produto)?.nome;
      this.formAdicionarReserva.preco_produto =
        estabelecimentoAtivo.produtos.find((x) => x.id == id_produto)?.preco;
      this.formAdicionarReserva.nome_funcionario =
        estabelecimentoAtivo.funcionarios.find(
          (x) => x.id == id_funcionario
        )?.nome;
      var validado = this.checkAllFields(this.formAdicionarReserva);
      if (!validado) {
        this.showErroAddReserva = true;

        return;
      }
      this.showErroAddReserva = false;
      let res = await api.adicioanrReservaBO(this.formAdicionarReserva);
      if (res.resultado == "Existe") {
        this.notifyVue(
          "top",
          "center",
          "Já existe uma reserva para esse utilizador neste intervalo de horas.",
          "info"
        );
        return;
      } else if (res.resultado == "OK") {
        this.marcarReservaModal = false;
        this.notifyVue(
          "top",
          "center",
          "Obrigado, a sua reserva foi criada.",
          "success"
        );

        this.formAdicionarReserva = {
          data: "",
          hora_inicio: "",
          hora_fim: "",
          funcionarios: [],
          id_funcionario: "",
          nome_funcionario: "",
          id_produto: "",
          nome_produto: "",
          produto: [],
          id_cliente: "NA",
          nome_cliente: "",
          email_cliente: "NA",
          telefone_cliente: "",
          estado_reserva: "confirmada",
          id_estabelecimento: "",
        };

        this.inicio();
        return;
      } else {
        this.notifyVue(
          "top",
          "center",
          "Ocorreu um erro, por favor tente mais tarde.",
          "danger"
        );
        return;
      }
    },
    isFilled(value) {
      if (typeof value === "string" && value.trim() !== "") {
        return true;
      }
      if (typeof value === "number" && value > 0) {
        return true;
      }
      if (Array.isArray(value) && value.length > 0) {
        return true;
      }
      if (value === "NA") {
        return true;
      }
      return false;
    },
    dragCreate(selectInfo) {
      if (!selectInfo.start || !selectInfo.end) {
        return;
      }
      this.lastCreatedEvent = selectInfo;
      var funcionarioEscolhido = selectInfo.split;

      var estabAtivo = localStorage.getItem("estabAtivo");
      var hora_inicio = this.converterData(selectInfo.start);
      var hora_fim = this.converterData(selectInfo.end);
      var data = this.extrairData(selectInfo.start);
      this.marcarReservaModal = true;
      this.formAdicionarReserva.hora_inicio = hora_inicio + ":00";
      this.formAdicionarReserva.hora_fim = hora_fim + ":00";
      this.formAdicionarReserva.data = data;
      this.formAdicionarReserva.id_estabelecimento = estabAtivo;
      this.formAdicionarReserva.id_funcionario = funcionarioEscolhido;

      var estabelecimentos = JSON.parse(
        localStorage.getItem("estabelecimentos")
      );
      for (let i = 0; i < estabelecimentos.length; i++) {
        estabelecimentos[i].dias_aberto = JSON.parse(
          estabelecimentos[i].dias_aberto
        );
        estabelecimentos[i].funcionarios = JSON.parse(
          estabelecimentos[i].funcionarios
        );
        estabelecimentos[i].horario = JSON.parse(estabelecimentos[i].horario);
        estabelecimentos[i].produtos = JSON.parse(estabelecimentos[i].produtos);
      }
      var estabelecimento = estabelecimentos.find(
        (x) => x.id_interno == estabAtivo
      );
      var tempProdutos = [];
      var tempArrayProdutos = estabelecimento.produtos;
      for (let i = 0; i < tempArrayProdutos.length; i++) {
        tempProdutos.push({
          name: tempArrayProdutos[i].nome,
          value: tempArrayProdutos[i].id,
        });
      }
      var tempFuncionarios = [];
      var tempArrayFuncionarios = estabelecimento.funcionarios;
      for (let i = 0; i < tempArrayFuncionarios.length; i++) {
        tempFuncionarios.push({
          name: tempArrayFuncionarios[i].nome,
          value: tempArrayFuncionarios[i].id,
        });
      }
      this.formAdicionarReserva.produto = tempProdutos;
      this.formAdicionarReserva.funcionarios = tempFuncionarios;
    },
    async aceitarReserva() {
      let json = {
        id_cliente: this.eventoInformacao.id_cliente,
        id: this.eventoInformacao.id,
      };
      var nome_Estab = this.estabelecimentos.find(
        (x) => x.id_interno == this.estabelecimentoSelecionado
      ).nome;
      const dataReserva = this.eventoInformacao.data;
      const token = this.eventoInformacao.notificacaoToken;
      let res = await api.aceitarReservas(json);
      if (res.resultado == "Erro: Data da reserva não encontrada.") {
        this.inicio();
        return;
      } else if (res.resultado == "Erro ao executar a atualização.") {
        this.inicio();
        return;
      } else if (res.resultado == "OK") {
        if (token && token != "") {
          var jsonNotificacao = {
            title: "Reserva",
            body:
              "A sua reserva no estabelecimento " +
              nome_Estab +
              " para o dia " +
              dataReserva +
              " foi aceite!",
            token: token,
          };
          let res2 = await api.enviarNotificacao(jsonNotificacao);
        }
        this.inicio();
        return;
      }
    },
    async apagarReserva() {
      let json = {
        id_cliente: this.eventoInformacao.id_cliente,
        id: this.eventoInformacao.id,
      };
      //sweetalert
      let res = await api.apagarReservas(json);

      if (res.resultado == "Erro: Data da reserva não encontrada.") {
        this.inicio();
        return;
      } else if (res.resultado == "Erro ao executar a atualização.") {
        this.inicio();
        return;
      } else if (res.resultado == "Erro: Nenhuma linha afetada.") {
        this.inicio();
        return;
      } else if (res.resultado == "OK") {
        this.inicio();
        return;
      }
    },
    notifyVue(verticalAlign, horizontalAlign, mensagem, tipo) {
      this.$notify({
        timeout: 2500,
        message: mensagem,
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: tipo,
      });
    },
    showSwal(type) {
      if (type === "apagar") {
        this.verInformacoesModal = false;
        var _this = this;
        Swal.fire({
          title: "Atenção",
          text: `Ao apagar esta reserva, não vai ser possivel recupera-la!`,
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-success",
            cancelButton: "md-button md-danger",
          },
          confirmButtonText: "Apagar",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            _this.apagarReserva();
          }
        });
      }
    },
    formatDate(date) {
      const pad = (number) => (number < 10 ? `0${number}` : number);

      const year = date.getFullYear();
      const month = pad(date.getMonth() + 1); // Months are zero-based
      const day = pad(date.getDate());
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    alterarEstabelecimento() {
      var id_interno = this.estabelecimentoSelecionado;
      localStorage.setItem("estabAtivo", id_interno);
      this.inicio();
    },
    converterData(data) {
      const dateString = data;
      const dateObj = new Date(dateString);
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      const formattedTime = `${hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
      return formattedTime;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async buscarUtilizadores() {
      let utilizadores = await api.buscarUtilizadores();
      this.utilizadores = Object.values(utilizadores.resultado);
    },
    handleEventClick(event) {
      var tempUser = this.utilizadores.find(
        (x) => x.user_id == event.id_cliente
      );
      var notificacaoToken = "";
      if (tempUser) {
        notificacaoToken = tempUser.notificacao_token;
      }
      this.verInformacoesModal = true;
      var id = event.id;
      var hora_inicio = this.converterData(event.start);
      var hora_fim = this.converterData(event.end);
      var produto = event.title;
      var funcionario = event.nome_funcionario;
      var nome_cliente = event.nome_cliente;
      var telefone_cliente = event.telefone_cliente;
      var email_cliente = event.email_cliente;
      var fator_confianca = event.fator_confianca;
      var timestamp_reserva = event.timestamp_reserva;
      var id_cliente = event.id_cliente;
      var passado = event.passado;
      var estado_reserva = this.capitalizeFirstLetter(event.estado_reserva);
      var json = {
        id: id,
        data: event.data,
        hora_inicio: hora_inicio,
        hora_fim: hora_fim,
        produto: produto,
        nome_funcionario: funcionario,
        nome_cliente: nome_cliente,
        telefone_cliente: telefone_cliente,
        email_cliente: email_cliente,
        fator_confianca: fator_confianca,
        timestamp_reserva: timestamp_reserva,
        estado_reserva: estado_reserva,
        id_cliente: id_cliente,
        notificacaoToken: notificacaoToken,
        passado: passado,
        utilizador: id_cliente == "NA" ? false : true,
      };
      this.eventoInformacao = json;
    },
    toggleSplitDays() {
      this.splitDays[1].hide = !this.splitDays[1].hide;
    },
  },
  async mounted() {
    await this.inicio();
    await this.buscarUtilizadores();
  },
};
</script>

<style>
/* Add your custom styles here */
.vuecal__cell-split {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-right: 1px solid #8c82fc;
}
.vuecal__cell-split * {
  color: black !important;
}
.vuecal__cell-split .split-label {
  color: black;
  font-size: 18px;
  font-weight: 400;
}
.vuecal--day-view:not(.vuecal--overflow-x) .vuecal__split-days-headers {
  margin-left: 0;
}
.vuecal__time-column {
  border-right: 1px solid #8c82fc;
  border-left: 1px solid #8c82fc;
}
.vuecal__flex.vuecal__body {
  border-bottom: 1px solid #8c82fc;
}
.vuecal__flex.vuecal__menu {
  background: #8c82fc;
  border-start-start-radius: 10px;
  border-start-end-radius: 10px;
  color: #fff;
}
.day-split-header {
  background: #8c82fc;
  color: #fff;
}
.vuecal__time-column .vuecal__time-cell {
  color: #8c82fc;
  font-weight: 600;
  position: relative;
}
span.vuecal__time-cell-label {
  position: absolute;
  top: 25%;
  left: 15%;
}
.vuecal__event {
  box-shadow: 1px 1px 6px #8c82fc;
}
.vuecal__cell-events-count {
  background: #8c82fc;
  left: 20%;
  top: 3px;
  height: 20px;
  line-height: 12px;
  padding: 0 7px;
  font-size: 12px;
  align-items: center;
  display: flex;
}
/* Different color for different event types */
.vuecal__event.leisure {
  background-color: rgba(253, 156, 66, 0.9);
  border: 1px solid rgb(233, 136, 46);
  color: #fff;
}
.vuecal__event.health {
  background-color: rgba(164, 230, 210, 0.9);
  border: 1px solid rgb(144, 210, 190);
}
.vuecal__event.sport {
  background-color: rgba(255, 102, 102, 0.9);
  border: 1px solid rgb(235, 82, 82);
  color: #fff;
}
.icon_green {
  color: green !important;
}
.icon_orange {
  color: orange !important;
  position: absolute;
  top: 0;
  right: 15;
}
.vuecal__event {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-items: center;
}
.vuecal__header {
  background: #eeeeee !important;
}
.mais45 .vuecal__event {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
}
.vuecal__event--dragging {
  background-color: rgba(60, 60, 60, 0.3);
}
.event-orange.vuecal__event {
  border-right: 5px solid orange;
}
.event-green.vuecal__event {
  border-right: 5px solid green;
}
.mais45 .vuecal__event-time {
  position: absolute;
  bottom: 0;
  font-weight: 600;
  font-size: 12px;
}
.vuecal__event-time {
  font-weight: 600;
  font-size: 12px;
}
</style>
