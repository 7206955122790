<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>
<style>
.buttonApp {
  background-color: #000 !important;
  border: 1px solid black;
  padding: 5px 10px !important;
  border-radius: 10px;
}
.buttonApp:hover {
  background-color: #fff !important;
  border: 1px solid black;
  color: #000 !important;
  font-weight: 600;
}
.fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
  width: 15% !important;
}
.text-left {
  text-align: left !important;
}
table.fc-scrollgrid tr {
  background: white !important;
}
.bold {
  font-weight: 600;
}
.fc-timegrid-now-indicator-container {
  background: white !important;
}
.event-orange * {
  color: #000 !important;
}
.event-green * {
  color: #000 !important;
}
.mt-5 {
  margin-top: 5%;
}
.mt-3 {
  margin-top: 3%;
}
.pt-5 {
  padding-top: 5%;
}
.mb-5 {
  margin-bottom: 5%;
}
.pb-5 {
  padding-bottom: 5%;
}
.mr-5 {
  margin-right: 5%;
}
.pr-5 {
  padding-right: 5%;
}
.modal-mask {
  z-index: 99 !important;
}
.erro {
  color: red;
  font-weight: 600;
}
.sucesso {
  color: #8c82fc;
  font-weight: 600;
}
table.fc-scrollgrid-sync-table,
.md-toolbar-section-start,
.fixed-plugin,
footer {
  display: none;
}
.centercenter {
  justify-content: center !important;
}
.md-card-pricing ul {
  max-width: 100%;
}
span.fas.fa-spinner.fa-3x.fa-spin {
  color: #8c82fc !important;
}
.loading-overlay {
  display: flex;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
}

.checkboxWrapper {
  display: flex;
  position: relative;
}
.pointer {
  cursor: pointer;
}
.horarioInputs {
  width: 33%;
  float: left;
  box-sizing: border-box;
  padding-right: 10px;
}
.file-input {
  display: block !important;
}
.file-input .button-container {
  justify-content: flex-start !important;
}
</style>
