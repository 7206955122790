// =========================================================
// * Vue Material Dashboard PRO - v1.5.3
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2024 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
// import VueScheduler from "vue-scheduler";
// Plugins
import App from "./App.vue";
import Chartist from "chartist";

// router setup
import routes from "./routes/routes";

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
// Vue.use(VueScheduler);
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  // scrollBehavior: (to) => {
  //   if (to.hash) {
  //     return { selector: to.hash, behavior: "smooth" };
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
  linkExactActiveClass: "nav-item active",
});

// global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  let id = localStorage.getItem("appBackofficeLogin");
  if (
    to.name != "Login" &&
    to.name != "ChangePassword" &&
    to.name != "Register" &&
    id == undefined
  )
    next({ name: "Login" });
  else next();
});
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
});
