<template>
  <div v-if="rendered">
    <form>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
          <h4 class="title">
            Perfil Comerciante -
            <small>{{ user.nome }}</small>
          </h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Email</label>
                <md-input v-model="user.email" disabled></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Nome</label>
                <md-input v-model="user.nome" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Password</label>
                <md-input v-model="user.password" type="password"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Confirmar Password</label>
                <md-input
                  v-model="user.confirmarPassword"
                  type="password"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Morada</label>
                <md-input v-model="user.morada" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Cidade</label>
                <md-input v-model="user.cidade" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Código Postal</label>
                <md-input v-model="user.codigo_postal" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                @click="atualizarPerfil()"
                class="md-raised md-success mt-4"
                >Atualizar perfil</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
    <div class="md-layout-item md-small-size-100 md-size-33 mt-5 mb-5">
      <md-field>
        <label for="movies">Estabelecimento</label>
        <md-select
          v-model="estabelecimentoSelecionadoID"
          @input="alterarEstabelecimento"
          name="Estabelecimentos"
          id="Estabelecimentos">
          <md-option
            v-for="(item, i) in detalhesEstabelecimentos"
            :key="i"
            :value="item.id_interno"
            >{{ item.nome }}</md-option
          >
        </md-select>
      </md-field>
    </div>
    <form>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
          <h4 class="title">
            Perfil Estabelecimento -
            <b>{{ estabelecimentoSelecionado.nome }}</b>
          </h4>
          <div class="md-layout-item md-size-50 md-small-size-100"></div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-100 mt-3">
              <span class="pointer">
                <b>Fechar estabelecimento</b
                ><md-icon style="color: #8c82fc">info</md-icon>
                <md-tooltip md-direction="bottom"
                  >Serve para fechar a loja em caso de férias, enquanto estiver
                  fechado não serão permitidas reservas na App</md-tooltip
                >
              </span>
              <md-switch
                v-model="estabelecimentoSelecionado.temporariamente_fechado"
                @change="fecharEstab(estabelecimentoSelecionado)"
                >{{
                  estabelecimentoSelecionado.temporariamente_fechado
                    ? "Estabelecimento fechado"
                    : "Estabelecimento aberto"
                }}</md-switch
              >
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50 mt-5">
              <md-field>
                <label>Nome</label>
                <md-input
                  v-model="estabelecimentoSelecionado.nome"
                  type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50 mt-5">
              <md-field>
                <label>Telefone</label>
                <md-input
                  @keyup="alterouMorada = true"
                  v-model="estabelecimentoSelecionado.telefone"
                  type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33 mt-5">
              <md-field>
                <label>Morada</label>
                <md-input
                  @keyup="alterouMorada = true"
                  v-model="estabelecimentoSelecionado.morada"
                  type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33 mt-5">
              <md-field>
                <label>Cidade</label>
                <md-input
                  @keyup="alterouMorada = true"
                  v-model="estabelecimentoSelecionado.cidade"
                  type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33 mt-5">
              <md-field>
                <label>Código Postal</label>
                <md-input
                  v-model="estabelecimentoSelecionado.codigo_postal"
                  type="text"></md-input>
              </md-field>
            </div>
            <!-- <div class="md-layout-item md-small-size-100 md-size-33 mt-5">
              <span class="pointer">
                <b>Funcionários</b><md-icon style="color: red">info</md-icon>
                <md-tooltip md-direction="bottom"
                  >Depois de colocar a morada, carregue neste botão para
                  atribuir as coordenadas ao seu estabelecimento, só com essas
                  coordenadas é que o seu estabelecimento irá aparecer no mapa
                  na App</md-tooltip
                >
              </span>
              <md-button
                class="md-raised md-primary"
                style="display: block"
                @click="pesquisarCoordenadas"
                >Pesquisar Coordenadas</md-button
              >
            </div> -->

            <div class="md-layout-item md-small-size-100 md-size-100 mt-5">
              <span class="pointer">
                <b>Intervalo entre tabalhos (minutos)</b
                ><md-icon style="color: #8c82fc">info</md-icon>
                <md-tooltip md-direction="bottom"
                  >Introduza o tempo médio por tarefa, este valor será utilizado
                  para as reservas na Aplicação (ex: 60)
                </md-tooltip>
              </span>
              <md-field>
                <md-input
                  v-model="estabelecimentoSelecionado.intervalo"
                  type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100 mt-5">
              <p><b>Horário de funcionamento</b></p>
              <md-field
                class="inputNoBorder"
                v-for="(item, i) in estabelecimentoSelecionado.horario"
                :key="i">
                <div class="mr-5 multipleInputHolder horarioInputs">
                  <p>{{ item.dia }}</p>
                </div>
                <div class="mr-5 multipleInputHolder horarioInputs">
                  <md-select
                    v-model="item.abre"
                    :name="'abre-' + i"
                    :id="'abre-' + i">
                    <md-option
                      v-for="hora in horasDisponiveis"
                      :key="hora"
                      :value="hora"
                      >{{ hora }}</md-option
                    >
                  </md-select>
                </div>
                <div class="mr-5 multipleInputHolder horarioInputs">
                  <md-select
                    v-model="item.fecha"
                    :name="'fecha-' + i"
                    :id="'fecha-' + i">
                    <md-option
                      v-for="hora in horasDisponiveis"
                      :key="hora"
                      :value="hora"
                      >{{ hora }}</md-option
                    >
                  </md-select>
                </div>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100 mt-5">
              <span class="pointer">
                <b>Funcionários</b
                ><md-icon style="color: #8c82fc">info</md-icon>
                <md-tooltip md-direction="bottom"
                  >Coloque o nome do funcionário, se não quiser fazer upload de
                  um avatar deixe em branco, uma imagem por defeito será
                  atribuida.</md-tooltip
                >
              </span>

              <md-field
                class="inputNoBorder"
                v-for="(item, i) in estabelecimentoSelecionado.funcionarios"
                :key="i">
                <div class="mr-5 multipleInputHolder horarioInputs">
                  <md-input
                    class="pr-5"
                    v-model="item.nome"
                    type="text"
                    placeholder="Nome do Funcionário"></md-input>
                </div>
                <div class="mr-5 multipleInputHolder horarioInputs">
                  <md-input
                    class="pr-5"
                    v-model="item.avatar"
                    type="text"
                    placeholder="Avatar (URL)"></md-input>
                </div>
                <div class="mr-5">
                  <p @click="removerFuncionario(i)">
                    <md-icon style="color: red" class="pointer"
                      >delete_outline</md-icon
                    >
                  </p>
                </div>
              </md-field>
              <div
                class="mr-5 multipleInputHolder horarioInputs"
                v-if="
                  maximoFuncionarios >
                  estabelecimentoSelecionado.funcionarios.length
                ">
                <md-button
                  class="md-raised md-success mt-4"
                  @click="adicionarFuncionario"
                  >Adicionar Funcionário</md-button
                >
              </div>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-100 mt-5">
              <span class="pointer">
                <b>Dias de funcionamento</b
                ><md-icon style="color: #8c82fc">info</md-icon>
                <md-tooltip md-direction="bottom"
                  >Selecione os dias em que o estabelecimento está
                  aberto</md-tooltip
                >
              </span>
              <div class="checkboxWrapper">
                <md-field
                  class="inputNoBorder row"
                  v-for="(item, i) in estabelecimentoSelecionado.dias_aberto"
                  :key="i">
                  <md-checkbox v-model="item.estado"></md-checkbox>
                  <p>{{ item.dia }}</p>
                </md-field>
              </div>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100 mt-5">
              <span class="pointer">
                <b>Produtos</b><md-icon style="color: #8c82fc">info</md-icon>
                <md-tooltip md-direction="bottom"
                  >Coloque o nome do produto (ex: Corte simples), o preço terá
                  de ser só o número, sem qualquer sinal (ex: 15)</md-tooltip
                >
              </span>
              <md-field
                class="inputNoBorder"
                v-for="(item, i) in estabelecimentoSelecionado.produtos"
                :key="i">
                <div class="mr-5 multipleInputHolder horarioInputs">
                  <md-input
                    class="pr-5"
                    v-model="item.nome"
                    type="text"
                    placeholder="Produto"></md-input>
                </div>
                <div class="mr-5 multipleInputHolder horarioInputs">
                  <md-input
                    class="pr-5"
                    v-model="item.preco"
                    type="text"
                    placeholder="Preço"></md-input>
                </div>
                <div class="mr-5 multipleInputHolder horarioInputs">
                  <md-select
                    v-model="item.categoria"
                    :name="'fecha-' + item.categoria"
                    :id="'fecha-' + item.categoria"
                    placeholder="Para quem">
                    <md-option value="Mulheres">Mulheres</md-option>
                    <md-option value="Homens">Homens</md-option>
                  </md-select>
                </div>
                <div class="mr-5 multipleInputHolder horarioInputs">
                  <md-select
                    v-model="item.subcategoria"
                    :name="'fecha-' + item.subcategoria"
                    :id="'fecha-' + item.subcategoria"
                    placeholder="Categoria">
                    <md-option
                      v-for="(cat, i) in subCategorias"
                      :key="i"
                      :value="cat.value"
                      >{{ cat.label }}</md-option
                    >
                  </md-select>
                </div>
                <div class="mr-5">
                  <p @click="removerProduto(i)">
                    <md-icon style="color: red" class="pointer"
                      >delete_outline</md-icon
                    >
                  </p>
                </div>
              </md-field>
              <div class="mr-5 multipleInputHolder horarioInputs">
                <md-button
                  class="md-raised md-success mt-4"
                  @click="adicionarProduto"
                  >Adicionar Produto</md-button
                >
              </div>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100 mt-5">
              <span class="pointer">
                <b>Logotipo</b><md-icon style="color: #8c82fc">info</md-icon>
                <md-tooltip md-direction="bottom"
                  >O logotipo é a imagem que aparecerá na App, poderá ser também
                  uma foto da frente do estabelecimento
                </md-tooltip>
              </span>
              <div class="file-input mt-5">
                <div v-if="!estabelecimentoSelecionado.logo">
                  <div class="image-container">
                    <img :src="estabelecimentoSelecionado.logo" title="" />
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="estabelecimentoSelecionado.logo" />
                </div>
                <div class="button-container">
                  <md-button
                    class="md-danger"
                    @click="removeImage"
                    v-if="estabelecimentoSelecionado.logo"
                    ><i class="fa fa-times"></i>Remove</md-button
                  >
                  <md-button class="md-success md-fileinput">
                    <template v-if="!estabelecimentoSelecionado.logo"
                      >Select image</template
                    >
                    <template v-else>Change</template>
                    <input type="file" @change="onFileChange" />
                  </md-button>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                class="md-raised md-success mt-4"
                @click="atualizarEstabelecimento()"
                >Atualizar estabelecimento</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </div>
  <div v-else>
    <div class="loading-overlay">
      <span class="fas fa-spinner fa-3x fa-spin"></span>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import api from "../../api";
import { asRoughMinutes } from "@fullcalendar/core/internal";
export default {
  name: "edit-profile-form",

  data() {
    return {
      diasSemana: [
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
        "Domingo",
      ],
      subCategorias: [],
      user: {},

      maximoFuncionarios: 5,
      funcionariosQty: 0,
      rendered: false,
      username: null,
      disabled: null,
      emailadress: null,
      lastname: null,
      firstname: null,
      address: null,
      city: null,
      country: null,
      code: null,
      alterouMorada: false,
      estabelecimentosDropDown: [],
      detalhesEstabelecimentos: [],
      estabelecimentoSelecionado: {},
      estabelecimentoSelecionadoID: "",
      horasDisponiveis: [],
    };
  },
  methods: {
    async pesquisarCoordenadas() {
      var _this = this;
      const endereco = `${this.estabelecimentoSelecionado.morada}, ${this.estabelecimentoSelecionado.cidade}, ${this.estabelecimentoSelecionado.codigo_postal}, Portugal`;
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ address: endereco }, (results, status) => {
        if (status === "OK" && results && results.length > 0) {
          const location = results[0].geometry.location;
          _this.estabelecimentoSelecionado.localizacao[0].latitude =
            location.lat();
          _this.estabelecimentoSelecionado.localizacao[0].longitude =
            location.lng();
          _this.notifyVue(
            "top",
            "center",
            "Coordenadas atualizadas, Lat:" +
              location.lat() +
              " Lng: " +
              location.lng(),
            "success"
          );
        }
      });
    },

    adicionarProduto() {
      this.estabelecimentoSelecionado.produtos.push({ nome: "", preco: "" });
    },

    removerProduto(index) {
      this.estabelecimentoSelecionado.produtos.splice(index, 1);
    },

    isDiaSelecionado(dia, index) {
      return this.estabelecimentoSelecionado.horario.some(
        (item, i) => item.dia === dia && i !== index
      );
    },

    async atualizarEstabelecimento() {
      this.rendered = false;
      var avatar_placeholder_url =
        "https://api.republica45.pt/api-testes-cantador/public/uploads/avatar_placeholder.png";
      if (this.estabelecimentoSelecionado.temporariamente_fechado) {
        this.estabelecimentoSelecionado.temporariamente_fechado = "1";
      } else {
        this.estabelecimentoSelecionado.temporariamente_fechado = "0";
      }

      //ATRIBUIR ID AOS FUNCIONARIOS ADICIONADOS

      for (
        let i = 0;
        i < this.estabelecimentoSelecionado.funcionarios.length;
        i++
      ) {
        if (this.estabelecimentoSelecionado.funcionarios[i].avatar == "") {
          this.estabelecimentoSelecionado.funcionarios[i].avatar =
            avatar_placeholder_url;
        }
        if (!this.estabelecimentoSelecionado.funcionarios[i].id) {
          this.estabelecimentoSelecionado.funcionarios[i].id =
            this.generateId();
        }
      }
      //ATRIBUIR ID AOS PRODUTOS ADICIONADOS
      for (
        let i = 0;
        i < this.estabelecimentoSelecionado.produtos.length;
        i++
      ) {
        if (this.estabelecimentoSelecionado.produtos[i].avatar == "") {
          this.estabelecimentoSelecionado.produtos[i].avatar =
            avatar_placeholder_url;
        }
        if (!this.estabelecimentoSelecionado.produtos[i].id) {
          this.estabelecimentoSelecionado.produtos[i].id = this.generateId();
        }
      }
      var produtosOK = true;
      for (
        let i = 0;
        i < this.estabelecimentoSelecionado.produtos.length;
        i++
      ) {
        if (
          !this.estabelecimentoSelecionado.produtos[i].categoria ||
          this.estabelecimentoSelecionado.produtos[i].categoria.trim() == ""
        ) {
          produtosOK = false;
        }
        if (
          !this.estabelecimentoSelecionado.produtos[i].subcategoria ||
          this.estabelecimentoSelecionado.produtos[i].subcategoria.trim() == ""
        ) {
          produtosOK = false;
        }
      }

      if (!produtosOK) {
        setTimeout(() => {
          this.rendered = true;
          this.inicio();
          this.notifyVue(
            "top",
            "center",
            "Todos os campos nos produtos são obrigatórios!",
            "danger"
          );
        }, 2500);
        return;
      }
      if (this.alterouMorada) {
        await this.pesquisarCoordenadas();
      }
      this.estabelecimentoSelecionado.localizacao = JSON.stringify(
        this.estabelecimentoSelecionado.localizacao
      );
      this.estabelecimentoSelecionado.horario = JSON.stringify(
        this.estabelecimentoSelecionado.horario
      );
      this.estabelecimentoSelecionado.dias_aberto = JSON.stringify(
        this.estabelecimentoSelecionado.dias_aberto
      );
      this.estabelecimentoSelecionado.funcionarios = JSON.stringify(
        this.estabelecimentoSelecionado.funcionarios
      );
      this.estabelecimentoSelecionado.produtos = JSON.stringify(
        this.estabelecimentoSelecionado.produtos
      );
      let res = await api.atualizarEstabelecimentoAPI(
        this.estabelecimentoSelecionado
      );
      if (res.resultado == "OK") {
        this.estabelecimentoSelecionado.localizacao = JSON.parse(
          this.estabelecimentoSelecionado.localizacao
        );
        this.estabelecimentoSelecionado.horario = JSON.parse(
          this.estabelecimentoSelecionado.horario
        );
        this.estabelecimentoSelecionado.dias_aberto = JSON.parse(
          this.estabelecimentoSelecionado.dias_aberto
        );
        this.estabelecimentoSelecionado.funcionarios = JSON.parse(
          this.estabelecimentoSelecionado.funcionarios
        );
        this.estabelecimentoSelecionado.produtos = JSON.parse(
          this.estabelecimentoSelecionado.produtos
        );

        setTimeout(() => {
          this.rendered = true;
          this.inicio();
          this.notifyVue(
            "top",
            "center",
            "O estabelecimento foi atualizado.",
            "success"
          );
        }, 2500);
        return;
      }
      if (res.resultado == "Erro: Nenhuma linha afetada.") {
        this.estabelecimentoSelecionado.localizacao = JSON.parse(
          this.estabelecimentoSelecionado.localizacao
        );
        this.estabelecimentoSelecionado.horario = JSON.parse(
          this.estabelecimentoSelecionado.horario
        );
        this.estabelecimentoSelecionado.dias_aberto = JSON.parse(
          this.estabelecimentoSelecionado.dias_aberto
        );
        this.estabelecimentoSelecionado.funcionarios = JSON.parse(
          this.estabelecimentoSelecionado.funcionarios
        );
        this.estabelecimentoSelecionado.produtos = JSON.parse(
          this.estabelecimentoSelecionado.produtos
        );
        setTimeout(() => {
          this.rendered = true;
          this.inicio();
        }, 2500);
        return;
      } else {
        this.estabelecimentoSelecionado.localizacao = JSON.parse(
          this.estabelecimentoSelecionado.localizacao
        );
        this.estabelecimentoSelecionado.horario = JSON.parse(
          this.estabelecimentoSelecionado.horario
        );
        this.estabelecimentoSelecionado.dias_aberto = JSON.parse(
          this.estabelecimentoSelecionado.dias_aberto
        );
        this.estabelecimentoSelecionado.funcionarios = JSON.parse(
          this.estabelecimentoSelecionado.funcionarios
        );
        this.estabelecimentoSelecionado.produtos = JSON.parse(
          this.estabelecimentoSelecionado.produtos
        );
        setTimeout(() => {
          this.rendered = true;
          this.inicio();
          this.notifyVue(
            "top",
            "center",
            "Ocorreu um erro, tente por favor mais tarde.",
            "danger"
          );
        }, 2500);
        return;
      }
      //MUDAR
    },

    adicionarFuncionario() {
      this.estabelecimentoSelecionado.funcionarios.push({
        nome: "",
        avatar: "",
      });
    },

    removerFuncionario(index) {
      this.estabelecimentoSelecionado.funcionarios.splice(index, 1);
    },

    removeImage() {
      return true;
    },

    onFileChange(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id_interno", this.estabelecimentoSelecionado.id_interno);
      axios
        .post(
          "https://api.republica45.pt/api-testes-cantador/public/file.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.estabelecimentoSelecionado.logo = response.data.url;
        })
        .catch((error) => {
          return;
        });
    },

    generateId() {
      let id = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 16; i++) {
        if (i !== 0 && i % 4 === 0) {
          id += "-";
        }
        id += characters.charAt(Math.floor(Math.random() * characters.length));
      }

      return id;
    },

    async inicio() {
      this.user = JSON.parse(localStorage.getItem("appBackofficeLogin"));
      this.user.password = "";
      this.user.confirmarPassword = "";
      await this.atualizarEstabelecimentoLS();
      var estabelecimentos = JSON.parse(
        localStorage.getItem("estabelecimentos")
      );
      let res = await api.listarSubCategorias();
      res = Object.values(res);
      var tempSB = [];
      for (let i = 0; i < res.length; i++) {
        tempSB.push({
          label: res[i].nome,
          value: res[i].nome,
        });
      }
      this.subCategorias = tempSB;
      for (let i = 0; i < estabelecimentos.length; i++) {
        if (estabelecimentos[i].temporariamente_fechado == "1") {
          estabelecimentos[i].temporariamente_fechado = true;
        } else {
          estabelecimentos[i].temporariamente_fechado = false;
        }
        estabelecimentos[i].localizacao = JSON.parse(
          estabelecimentos[i].localizacao
        );
        estabelecimentos[i].horario = JSON.parse(estabelecimentos[i].horario);
        estabelecimentos[i].dias_aberto = JSON.parse(
          estabelecimentos[i].dias_aberto
        );
        estabelecimentos[i].funcionarios = JSON.parse(
          estabelecimentos[i].funcionarios
        );
        estabelecimentos[i].produtos = JSON.parse(estabelecimentos[i].produtos);
      }
      this.detalhesEstabelecimentos = estabelecimentos;
      this.funcionariosQty = this.estabelecimentoSelecionadoID =
        this.detalhesEstabelecimentos[0].id_interno;
      this.estabelecimentoSelecionado = this.detalhesEstabelecimentos.find(
        (x) => x.id_interno == this.estabelecimentoSelecionadoID
      );
      setTimeout(() => {
        this.rendered = true;
      }, 2500);

      //Perfil estabelecimento
    },

    async atualizarEstabelecimentoLS() {
      let user = JSON.parse(localStorage.getItem("appBackofficeLogin"));
      var estabelecimentos = user.estabelecimento_id.split("|");
      var estabelecimentosDetalhes = [];
      for (let i = 0; i < estabelecimentos.length; i++) {
        var jsonDetalhes = {
          id_interno: estabelecimentos[i],
        };
        let res2 = await api.buscarFuncionariosEstabelecimento(jsonDetalhes);
        estabelecimentosDetalhes.push(res2.resultado);
      }

      localStorage.setItem(
        "estabelecimentos",
        JSON.stringify(estabelecimentosDetalhes)
      );
    },

    alterarEstabelecimento() {
      this.estabelecimentoSelecionado = this.detalhesEstabelecimentos.find(
        (x) => x.id_interno == this.estabelecimentoSelecionadoID
      );
    },

    async atualizarPerfil() {
      let res = await api.atualizarPerfilComerciante(this.user);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "center",
          "O seu perfil foi atualizado.",
          "success"
        );
        localStorage.setItem("appBackofficeLogin", JSON.stringify(this.user));
        this.inicio();
      } else {
        this.notifyVue(
          "top",
          "center",
          "Ocorreu um erro, por favor tente mais tarde.",
          "danger"
        );
        this.inicio();
        return;
      }
    },

    notifyVue(verticalAlign, horizontalAlign, mensagem, tipo) {
      this.$notify({
        timeout: 2500,
        message: mensagem,
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: tipo,
      });
    },

    fecharEstab(item) {
      var fech = item.temporariamente_fechado ? "1" : "0";

      var json = {
        id_interno: item.id_interno,
        temporariamente_fechado: fech,
      };
    },
  },
  async mounted() {
    await this.inicio();
  },
  created() {
    for (let hora = 6; hora <= 24; hora++) {
      let horaFormatada = hora.toString().padStart(2, "0") + ":00";
      this.horasDisponiveis.push(horaFormatada);
    }
  },
};
</script>
<style></style>
