<template>
  <div v-if="rendered">
    <div class="md-layout">
      <div class="md-layout-item md-size-80">
        <div class="md-layout-item md-small-size-100 md-size-33">
          <md-field>
            <label for="movies">Estabelecimento</label>
            <md-select
              v-model="estabelecimentoSelecionado"
              @input="alterarEstabelecimento"
              name="Estabelecimentos"
              id="Estabelecimentos">
              <md-option
                v-for="(item, i) in estabelecimentosDropDown"
                :key="i"
                :value="item.value"
                >{{ item.name }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </div>
    </div>

    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon">
              <md-icon>book</md-icon>
            </div>
            <p class="category">Reservas hoje</p>
            <h3 class="title bold text-success">
              <animated-number :value="reservasHoje"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon class="text-success">done</md-icon>
              Apenas reservas confirmadas
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon">
              <md-icon>euro</md-icon>
            </div>
            <p class="category">Receita diária esperada</p>
            <h3 class="title bold text-success">
              <animated-number :value="receitaDiaria"></animated-number>
              €
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon class="text-success">done</md-icon>
              Apenas reservas confirmadas
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon">
              <md-icon>book</md-icon>
            </div>
            <p class="category">Total de vendas</p>
            <h3 class="title bold text-success">
              <animated-number :value="totalVendas"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon class="text-success">done</md-icon>
              Desde que se registou
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon">
              <md-icon>star_rate</md-icon>
            </div>
            <p class="category">Produto mais vendido</p>
            <p class="title text-success bold">
              {{ nomeProdMaisVendido }}
            </p>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon class="text-success">done</md-icon>
              <span class="text-success bold">
                <animated-number
                  :value="quantidadeProdVendido"></animated-number>
              </span>
              Vendidos desde que se registou
            </div>
          </template>
        </stats-card>
      </div>

      <div class="md-layout-item md-size-100">
        <global-sales-card header-color="green">
          <template slot="header">
            <div class="card-icon">
              <md-icon>euro</md-icon>
            </div>
            <h4 class="title">Vendas por produto</h4>
          </template>

          <template slot="content">
            <div>
              <div class="md-layout-item">
                <table class="table">
                  <!-- Cabeçalhos da Tabela -->
                  <thead>
                    <tr>
                      <th>Produto</th>
                      <th>Quantidade</th>
                      <th>Total €</th>
                      <th>Total %</th>
                    </tr>
                  </thead>

                  <!-- Linhas da Tabela -->
                  <tbody>
                    <tr v-for="(item, i) in estatisticaVendas" :key="i">
                      <td>{{ item.produto }}</td>
                      <td>{{ item.quantidade }}</td>
                      <td>{{ item.soma_preco }}</td>
                      <td>{{ item.percentagem }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="md-layout-item md-size-50">
                <!-- <async-world-map class="map" :data="mapData"></async-world-map> -->
              </div>
            </div>
          </template>
        </global-sales-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
        <product-card header-animation="true">
          <img class="img" slot="imageHeader" :src="product1" />
          <md-icon slot="fixed-button">build</md-icon>
          <template slot="first-button">
            <md-icon>art_track</md-icon>
            <md-tooltip md-direction="bottom">View</md-tooltip>
          </template>
          <template slot="second-button">
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="bottom">Edit</md-tooltip>
          </template>
          <template slot="third-button">
            <md-icon>close</md-icon>
            <md-tooltip md-direction="bottom">Remove</md-tooltip>
          </template>
          <h4 slot="title" class="title">
            <a href="#pablo">Cozy 5 Stars Apartment</a>
          </h4>
          <div slot="description" class="card-description">
            The place is close to Barceloneta Beach and bus stop just 2 min by
            walk and near to "Naviglio" where you can enjoy the main night life
            in Barcelona.
          </div>
          <template slot="footer">
            <div class="price">
              <h4>$899/night</h4>
            </div>
            <div class="stats">
              <p class="category">
                <md-icon>place</md-icon>
                Barcelona, Spain
              </p>
            </div>
          </template>
        </product-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
        <product-card header-animation="true">
          <img class="img" slot="imageHeader" :src="product2" />
          <md-icon slot="fixed-button">build</md-icon>
          <template slot="first-button">
            <md-icon>art_track</md-icon>
            <md-tooltip md-direction="bottom">View</md-tooltip>
          </template>
          <template slot="second-button">
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="bottom">Edit</md-tooltip>
          </template>
          <template slot="third-button">
            <md-icon>close</md-icon>
            <md-tooltip md-direction="bottom">Remove</md-tooltip>
          </template>
          <h4 slot="title" class="title">
            <a href="#pablo">Office Studio</a>
          </h4>
          <div slot="description" class="card-description">
            The place is close to Metro Station and bus stop just 2 min by walk
            and near to "Naviglio" where you can enjoy the night life in London,
            UK.
          </div>
          <template slot="footer">
            <div class="price">
              <h4>$1.119/night</h4>
            </div>
            <div class="stats">
              <p class="category">
                <md-icon>place</md-icon>
                London, UK
              </p>
            </div>
          </template>
        </product-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
        <product-card header-animation="true">
          <img class="img" slot="imageHeader" :src="product3" />
          <md-icon slot="fixed-button">build</md-icon>
          <template slot="first-button">
            <md-icon>art_track</md-icon>
            <md-tooltip md-direction="bottom">View</md-tooltip>
          </template>
          <template slot="second-button">
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="bottom">Edit</md-tooltip>
          </template>
          <template slot="third-button">
            <md-icon>close</md-icon>
            <md-tooltip md-direction="bottom">Remove</md-tooltip>
          </template>
          <h4 slot="title" class="title">
            <a href="#pablo">Beautiful Castle</a>
          </h4>
          <div slot="description" class="card-description">
            The place is close to Metro Station and bus stop just 2 min by walk
            and near to "Naviglio" where you can enjoy the main night life in
            Milan.
          </div>
          <template slot="footer">
            <div class="price">
              <h4>$459/night</h4>
            </div>
            <div class="stats">
              <p class="category">
                <md-icon>place</md-icon>
                Milan, Italy
              </p>
            </div>
          </template>
        </product-card>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="loading-overlay">
      <span class="fas fa-spinner fa-3x fa-spin"></span>
    </div>
  </div>
</template>

<script>
import {
  StatsCard,
  ProductCard,
  AnimatedNumber,
  GlobalSalesCard,
} from "@/components";
import api from "../../api";
export default {
  components: {
    StatsCard,
    AnimatedNumber,
    ProductCard,
    GlobalSalesCard,
  },
  data() {
    return {
      estatisticaVendas: [],
      estabelecimentoSelecionado: "",
      estabelecimentosDropDown: [],
      estabelecimentos: [],
      utilizadores: [],
      rendered: false,
      reservasHoje: 0,
      receitaDiaria: 0,
      totalVendas: 0,
      quantidadeProdVendido: 0,
      nomeProdMaisVendido: "Sem dados",
      product1: "./img/card-2.jpg",
      product2: "./img/card-3.jpg",
      product3: "./img/card-1.jpg",
      seq2: 0,
    };
  },
  methods: {
    alterarEstabelecimento() {
      var id_interno = this.estabelecimentoSelecionado;
      localStorage.setItem("estabAtivo", id_interno);
      this.inicio();
    },
    async inicio() {
      this.rendered = false;
      let user = JSON.parse(localStorage.getItem("appBackofficeLogin"));
      let estabelecimento_id = user.estabelecimento_id.split("|")[0];
      let json = {
        estabelecimento_id: localStorage.getItem("estabAtivo")
          ? localStorage.getItem("estabAtivo")
          : estabelecimento_id.split("|")[0],
      };
      if (!localStorage.getItem("estabAtivo")) {
        localStorage.setItem("estabAtivo", estabelecimento_id.split("|")[0]);
      }

      this.estabelecimentoSelecionado = json.estabelecimento_id;
      var estabelecimentos = user.estabelecimento_id.split("|");
      var estabelecimentosDetalhes = [];
      let res = await api.buscarReservas(json);
      if (res) {
        if (res.resultado == "OK") {
          const temp = Object.values(res.data);
          var totalVendas = temp.filter(
            (x) => x.concluida == "1" && x.sucesso == "1"
          );
          var produtosVendidos = [];
          for (let i = 0; i < totalVendas.length; i++) {
            produtosVendidos.push({
              nome_produto: totalVendas[i].nome_produto,
              preco_produto: parseFloat(totalVendas[i].preco_produto),
            });
          }
          const contagemProdutos = produtosVendidos.reduce(
            (contador, produto) => {
              contador[produto.nome_produto] =
                (contador[produto.nome_produto] || 0) + 1;
              return contador;
            },
            {}
          );
          const somaPrecos = produtosVendidos.reduce((somas, produto) => {
            somas[produto.nome_produto] =
              (somas[produto.nome_produto] || 0) + produto.preco_produto;
            return somas;
          }, {});

          let produtoMaisFrequente = "";
          let maxCount = 0;
          let totalSales = produtosVendidos.length;

          for (const [produto, count] of Object.entries(contagemProdutos)) {
            if (count > maxCount) {
              maxCount = count;
              produtoMaisFrequente = produto;
            }
          }
          const porcentagens = [];
          for (const [produto, count] of Object.entries(contagemProdutos)) {
            porcentagens.push({
              produto: produto,
              quantidade: count,
              percentagem: ((count / totalSales) * 100).toFixed(2) + "%",
              soma_preco: somaPrecos[produto].toFixed(2) + "€",
            });
          }
          this.estatisticaVendas = porcentagens;
          this.quantidadeProdVendido = maxCount;
          this.nomeProdMaisVendido = produtoMaisFrequente;
          this.totalVendas = totalVendas.length;
          const hoje = new Date().toISOString().split("T")[0];
          const reservasHoje = temp.filter(
            (reserva) =>
              reserva.data === hoje && reserva.estado_reserva == "confirmada"
          );
          const totalReservasHoje = reservasHoje.length;
          this.reservasHoje = totalReservasHoje;
          this.estabelecimentosDropDown = [];
          for (let i = 0; i < estabelecimentos.length; i++) {
            var jsonDetalhes = {
              id_interno: estabelecimentos[i],
            };
            let res2 = await api.buscarFuncionariosEstabelecimento(
              jsonDetalhes
            );
            estabelecimentosDetalhes.push(res2.resultado);
          }
          var func = JSON.parse(
            estabelecimentosDetalhes.find(
              (x) => x.id_interno == estabelecimento_id
            )?.funcionarios
          );
          localStorage.setItem(
            "estabelecimentos",
            JSON.stringify(estabelecimentosDetalhes)
          );

          for (let i = 0; i < estabelecimentosDetalhes.length; i++) {
            this.estabelecimentosDropDown.push({
              value: estabelecimentosDetalhes[i].id_interno,
              name: estabelecimentosDetalhes[i].nome,
            });
            estabelecimentosDetalhes[i].dias_aberto = JSON.parse(
              estabelecimentosDetalhes[i].dias_aberto
            );
            estabelecimentosDetalhes[i].funcionarios = JSON.parse(
              estabelecimentosDetalhes[i].funcionarios
            );
            estabelecimentosDetalhes[i].horario = JSON.parse(
              estabelecimentosDetalhes[i].horario
            );
            estabelecimentosDetalhes[i].produtos = JSON.parse(
              estabelecimentosDetalhes[i].produtos
            );
          }
          this.estabelecimentos = estabelecimentosDetalhes;
          var detalhesEstabSelecionado = this.estabelecimentos.find(
            (x) => x.id_interno == this.estabelecimentoSelecionado
          );
          const totalDinheiroHoje = reservasHoje.reduce((total, reserva) => {
            const produto = detalhesEstabSelecionado.produtos.find(
              (prod) => prod.id == reserva.id_produto
            );
            return total + (produto ? parseFloat(produto.preco) : 0);
          }, 0);
          this.receitaDiaria = totalDinheiroHoje;
        } else if (
          res.resultado ==
          "Erro: Nenhuma reserva encontrada para este estabelecimento."
        ) {
          this.estabelecimentosDropDown = [];
          for (let i = 0; i < estabelecimentos.length; i++) {
            var jsonDetalhes = {
              id_interno: estabelecimentos[i],
            };
            let res2 = await api.buscarFuncionariosEstabelecimento(
              jsonDetalhes
            );
            estabelecimentosDetalhes.push(res2.resultado);
          }
          var func = JSON.parse(
            estabelecimentosDetalhes.find(
              (x) => x.id_interno == estabelecimento_id
            )?.funcionarios
          );
          localStorage.setItem(
            "estabelecimentos",
            JSON.stringify(estabelecimentosDetalhes)
          );
          for (let i = 0; i < estabelecimentosDetalhes.length; i++) {
            this.estabelecimentosDropDown.push({
              value: estabelecimentosDetalhes[i].id_interno,
              name: estabelecimentosDetalhes[i].nome,
            });
            estabelecimentosDetalhes[i].dias_aberto = JSON.parse(
              estabelecimentosDetalhes[i].dias_aberto
            );
            estabelecimentosDetalhes[i].funcionarios = JSON.parse(
              estabelecimentosDetalhes[i].funcionarios
            );
            estabelecimentosDetalhes[i].horario = JSON.parse(
              estabelecimentosDetalhes[i].horario
            );
            estabelecimentosDetalhes[i].produtos = JSON.parse(
              estabelecimentosDetalhes[i].produtos
            );
          }
          this.estabelecimentos = estabelecimentosDetalhes;
        }
        this.rendered = true;
      }
    },
  },
  async mounted() {
    await this.inicio();
  },
};
</script>

<style>
table.table {
  width: 100%;
}
.table thead th {
  text-align: left;
  color: #8c82fc;
}
</style>
