var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive },
  ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Calendário',
          icon: 'date_range',
          path: '/calendario',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Planos',
          icon: 'timeline',
          path: '/planos',
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('fixed-plugin',{attrs:{"color":_vm.sidebarBackground,"colorBg":_vm.sidebarBackgroundColor,"sidebarMini":_vm.sidebarMini,"sidebarImg":_vm.sidebarImg,"image":_vm.sidebarBackgroundImage},on:{"update:color":function($event){_vm.sidebarBackground=$event},"update:colorBg":function($event){_vm.sidebarBackgroundColor=$event},"update:color-bg":function($event){_vm.sidebarBackgroundColor=$event},"update:sidebarMini":function($event){_vm.sidebarMini=$event},"update:sidebar-mini":function($event){_vm.sidebarMini=$event},"update:sidebarImg":function($event){_vm.sidebarImg=$event},"update:sidebar-img":function($event){_vm.sidebarImg=$event},"update:image":function($event){_vm.sidebarBackgroundImage=$event}}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }