<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <login-card header-color="">
        <h4 slot="description" class="title" style="color: #000">Log in</h4>

        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input v-model="password" type="password"></md-input>
        </md-field>
        <p></p>
        <p
          v-if="mostrarErro"
          slot="erro"
          class="title"
          style="color: red; font-weight: 700; text-align: left">
          As credenciais estão erradas.
        </p>
        <md-button slot="footer" class="buttonApp" @click="login()">
          ENTRAR
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import api from "../../../api";
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      mostrarErro: false,
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      if (this.email.trim() == "" || this.password.trim() == "") {
        return;
      }
      let json = {
        email: this.email,
        password: this.password,
      };
      let res = await api.login(json);
      if (res.resultado == "Erro: Usuário ou senha incorretos.") {
        this.mostrarErro = true;
        return;
      } else if (res.resultado == "OK") {
        localStorage.setItem("appBackofficeLogin", JSON.stringify(res.data));
        this.$router.push("/dashboard");
      } else {
        this.mostrarErro = true;
        return;
      }
    },
  },
};
</script>

<style></style>
