/* eslint-disable */

import axios from "axios";

const API_EUPAGO_KEY = "ApiKey demo-9d35-2ae2-1866-6d6";
const instance = axios.create({
  baseURL: "https://api.republica45.pt/api-testes-cantador/public",
  headers: {
    "content-type": "application/json",
    Authorization: "Basic YXBwX3VzZXI6ZTg2YXNkSy83NUozKXBZfnNefn5ed0w=",
  },
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
});

export default {
  // SIGN IN
  async login(data) {
    try {
      const res = await instance.post("/dashboard/login", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async pagamentoBasico(data) {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://sandbox.eupago.pt/api/v1.02/creditcard/create",
      headers: {
        Authorization: API_EUPAGO_KEY,
        "Content-Type": "application/json",
        Cookie: "PHPSESSID=rfre3cne7khqbmg3i8m45djp21",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async buscarReservas(data) {
    try {
      const res = await instance.post("/dashboard/reservas/listar", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async aceitarReservas(data) {
    try {
      const res = await instance.post("/aceitar/reserva", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async adicioanrReservaBO(data) {
    try {
      const res = await instance.post("/inserir/reserva/backoffice", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async apagarReservas(data) {
    try {
      const res = await instance.post("/apagar/reserva", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async atualizarPerfilComerciante(data) {
    try {
      const res = await instance.post("/atualizar/perfil/comerciante", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async atualizarEstabelecimentoAPI(data) {
    try {
      const res = await instance.post("/atualizar/estabelecimento", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async buscarFuncionariosEstabelecimento(data) {
    try {
      const res = await instance.post(
        "/dashboard/estabelecimento/detalhes",
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async buscarUtilizadores(data) {
    try {
      const res = await instance.post("/dashboard/utilizadores/listar", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarSubCategorias(data) {
    try {
      const res = await instance.get("/listar/subcategorias", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async enviarNotificacao(data) {
    try {
      const res = await instance.post("/enviarNotificacao", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },

  async listarLeads(data) {
    try {
      const res = await instance.post("/dashboard/listar/leads", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarLeadsIncumprimento(data) {
    try {
      const res = await instance.post(
        "/dashboard/listar/leads/incumprimento",
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async converterLead(data) {
    try {
      const res = await instance.post("/dashboard/converter/leads", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
};
